import { Injectable } from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {NGXLogger} from "ngx-logger";
import {Observable,  of } from "rxjs";
import {map} from "rxjs/operators";
import {AuthService} from "../auth/auth.service";
@Injectable({
  providedIn: 'root'
})

export class LoginRedirectService implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private logger: NGXLogger) {
  }

  canActivate(): Observable<boolean> {
    if (this.authService.isAuthenticated()) {
      this.logger.debug("LoginRedirectService.canActivate IS authenticated");
      // if (localStorage.getItem('token')) {
      this.router.navigateByUrl('/organizations');
      return of(false);
    } else {
      this.logger.debug("LoginRedirectService.canActivate NOT authenticated");
      const user = this.authService.getUser();
      if (!user) {
        this.logger.debug('LoginRedirectService.canActivate IS NOT authenticated, redirecting to /login');
        return of(true);
      }

      this.logger.debug('LoginRedirectService.canActivate IS NOT authenticated but has token, attempting to validate.');
      return this.authService.validateSession().pipe(
        map(user => {
          if (!user) return true;
          this.logger.debug('LoginRedirectService.canActivate IS validated successfully.')
          this.router.navigateByUrl('/organizations');
          return false;
        }));
    }
  }
}
