import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {StoreService} from "./core/services/store.service";
import {filter, map} from "rxjs/operators";
import {AuthService} from "./auth/auth.service";


declare var jQuery: any;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  ngOnInit(): void {
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)).pipe(
      map(() => {
        let route = this.activatedRoute.firstChild;
        let child = route;

        while (child) {
          if (child.firstChild) {
            child = child.firstChild;
            route = child;
          } else {
            child = null;
          }
        }

        return route;
      }))
      .subscribe(
        route => {
          // console.log('updating organization in app component');
          const params = route.snapshot.params;
          const user = this.authService.getUser() || { principal: { organizationId: null } };

          const orgId = params.orgId || user.principal.organizationId;
          this.storeService.changeOrg(orgId);
        },
        error => {
          console.log('ERROR', error);
        }
      );
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private storeService: StoreService
  ) {}
}
