/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { Component, OnInit } from '@angular/core';
import { AppShellComponent } from './app-shell/app-shell.component';
import { StoreService } from './core/services/store.service';
import { NGXLogger } from 'ngx-logger';
import { Router, ActivatedRoute } from '@angular/router';
import {OrganizationService} from "./organizations/organization.service";
import {AuthService} from "./auth/auth.service";

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnInit {
  username: string;
  orgId: string;
  items: any[];

  constructor(
    public app: AppShellComponent,
    private authService: AuthService,
    private storeService: StoreService,
    private orgService: OrganizationService,
    private logger: NGXLogger,
    private route: ActivatedRoute,
    private router: Router) {
  }

  ngOnInit(): void {
    if(this.authService.isAuthenticated()) {
      const user = this.authService.getUser();
      this.username = user.name;
      this.logger.debug('AppTopBarComponent.ngOnInit() authenticated as '+this.username);

      this.items = [
        {
          label: 'Logout',
          icon: 'fa fa-fw fa-sign-out',
          command: () => this.logout()
        },
        {
          label: 'Notifications',
          icon: 'fa fa-fw fa-bell-o',
          routerLink: [ '/organizations/' + user.principal.organizationId + '/notifications' ]
        }
      ];

      const authority = (user.authorities || [])[0] || { authority: null };

      if (authority.authority === 'ROLE_PLATFORM') {
        this.getOrganizations();
        this.storeService.orgId
          .subscribe(
            org => this.getOrganizations()
          );
      }
    }
    this.logger.debug('AppTopBarComponent.ngOnInit() NOT authenticated');
  }

  getOrganizations() {
    const user = this.authService.getUser() || { principal: { organizationId: '' }};
    this.items = [
      {
        label: 'Logout',
        icon: 'fa fa-fw fa-sign-out',
        command: () => this.logout()
      },
      {
        label: 'Notifications',
        icon: 'fa fa-fw fa-bell-o',
        routerLink: [ '/organizations/' + user.principal.organizationId + '/notifications' ]
      }
    ];

    this.orgService.getOrganizations()
      .subscribe(
        orgs => {
          const activeOrgId = this.storeService.getOrgId() || '';

          const subItems = orgs._embedded.organizations.map(org => {
            const item = {
              label: org.name,
              routerLink: [ '/organizations/' + org.id ],
              style: undefined
            };

            if (org.id === activeOrgId) item.style = { 'font-weight': '600' };

            return item;
          });

          this.items.push({
            label: 'Organizations',
            items: subItems
          });
        },
        error => {
          this.logger.debug('ERROR GETTING ORGS', error);
        }
      )
  }

  themeChange(e) {
    const themeLink: HTMLLinkElement = <HTMLLinkElement>document.getElementById('theme-css');
    let href = themeLink.href;
    let themeFile = href.substring(href.lastIndexOf('/') + 1, href.lastIndexOf('.'));
    let themeTokens = themeFile.split('-');
    let themeName = themeTokens[1];
    let themeMode = themeTokens[2];
    let newThemeMode = (themeMode === 'dark') ? 'light' : 'dark';

    this.app.changeTheme(themeName + '-' + newThemeMode);
  }

  logout() {
    this.authService.logout();
  }
}
