import {Injectable} from '@angular/core';
import {AuthService} from './auth/auth.service';
import {CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {map} from "rxjs/operators";
import {Observable, of} from "rxjs";

@Injectable()
export class EnsureAuthenticatedService implements CanActivate {
    constructor(
        private authService: AuthService,
        private router: Router,
        private logger: NGXLogger) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const isAuthenticated = this.authService.isAuthenticated();
        this.logger.debug('ENSURE AUTH SERVICE', route, state)

        if (isAuthenticated) {
            this.logger.debug('EnsureAuthenticatedService.canActivate IS authenticated');
            this.logger.debug(state);
            const user = this.authService.getUser();
            if (state.url === '/') this.router.navigate([`/organizations/${user.principal.organizationId}`], {preserveQueryParams: true});
            return of(true);
        } else {
            const user = this.authService.getUser();
            if (!user) {
                this.logger.debug('EnsureAuthenticatedService.canActivate IS NOT authenticated, redirecting to /login');
                this.router.navigate(['/login'], {preserveQueryParams: true});
                return of(false);
            }


            this.logger.debug('EnsureAuthenticatedService.canActivate IS NOT authenticated but has token, attempting to validate.');
            return this.authService.validateSession().pipe(
                map(user => {
                    this.logger.debug('guard validate session', user)
                    if (!user) {
                        this.router.navigate(['/login'], {preserveQueryParams: true})
                        return false;
                    }
                    this.logger.debug('EnsureAuthenticatedService.canActivate IS validated successfully.')
                    if (state.url === '/') this.router.navigate([`/organizations/${user.principal.organizationId}`], {preserveQueryParams: true});
                    return true;
                }));
        }
    }
}
