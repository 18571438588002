import {Payment} from "./payment";

export class CcPayment extends Payment {
  cardType: string;
  cardNumber: string;
  expMonth: string;
  expYear: string;
  last4: string;
  readonly type = "CC";
}

