import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import {ModuleWithProviders} from '@angular/core';
import {DashboardDemoComponent} from './demo/view/dashboarddemo.component';
import {SampleDemoComponent} from './demo/view/sampledemo.component';
import {FormsDemoComponent} from './demo/view/formsdemo.component';
import {DataDemoComponent} from './demo/view/datademo.component';
import {PanelsDemoComponent} from './demo/view/panelsdemo.component';
import {OverlaysDemoComponent} from './demo/view/overlaysdemo.component';
import {MenusDemoComponent} from './demo/view/menusdemo.component';
import {MessagesDemoComponent} from './demo/view/messagesdemo.component';
import {ChartsDemoComponent} from './demo/view/chartsdemo.component';
import {FileDemoComponent} from './demo/view/filedemo.component';
import {MiscDemoComponent} from './demo/view/miscdemo.component';
import {EmptyDemoComponent} from './demo/view/emptydemo.component';
import {DocumentationComponent} from './demo/view/documentation.component';
import {EventRegistrationComponent} from "./event-registration/event-registration.component";
import {LoginComponent} from "./login/login.component";
import {LoginRedirectService} from "./login/login-redirect.service";
import {PageNotFoundComponent} from "./page-not-found/page-not-found.component";
import {OrganizationsComponent} from "./organizations/organizations.component";
import {AppShellComponent} from "./app-shell/app-shell.component";
import {AdminDashboardComponent} from "./admin/admin-dashboard/admin-dashboard.component";
import {EventRegistrationCompleteComponent} from "./event-registration/event-registration-complete/event-registration-complete.component";
import {OrdersComponent} from "./orders/orders.component";
import {OrderComponent} from "./orders/order/order.component";
import {EnsureAuthenticatedService} from "./ensure-authenticated.service";

const app_routes: Routes = [
    {path: 'dash', component: DashboardDemoComponent},
    {path: 'sample', component: SampleDemoComponent},
    {path: 'forms', component: FormsDemoComponent},
    {path: 'data', component: DataDemoComponent},
    {path: 'panels', component: PanelsDemoComponent},
    {path: 'overlays', component: OverlaysDemoComponent},
    {path: 'menus', component: MenusDemoComponent},
    {path: 'messages', component: MessagesDemoComponent},
    {path: 'charts', component: ChartsDemoComponent},
    {path: 'file', component: FileDemoComponent},
    {path: 'misc', component: MiscDemoComponent},
    {path: 'empty', component: EmptyDemoComponent},
    {path: 'documentation', component: DocumentationComponent},
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [LoginRedirectService]
    }
    ,
    {
        path: 'admin',
        component: AppShellComponent,
        children: [
            {path: '', component: AdminDashboardComponent},
        ]
    },

    {
        path: '',
        component: AppShellComponent,
        children: [
            {path: '', component: EventRegistrationComponent},
            {
                path: 'eventRegistration', component: EventRegistrationComponent, pathMatch: 'full',
            },
            {
                path: 'registrationComplete',
                component: EventRegistrationCompleteComponent
            },
            {
                path: '',
                pathMatch: 'full',
                component: OrganizationsComponent,
            },
            {
                path: 'organizations',
                component: OrganizationsComponent
            },
            {
                path: 'organizations/:orgId/orders',
                component: OrdersComponent,
                canActivate: [ EnsureAuthenticatedService ]
            },
            {
                path: 'organizations/:orgId/orders/:orderId',
                component: OrderComponent,
                canActivate: [ EnsureAuthenticatedService ]
            },
        ]
    },
    {
        path: '**',
        component: PageNotFoundComponent
    }
];

export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(app_routes, {
    enableTracing: false,
    preloadingStrategy: PreloadAllModules
});
