import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ConfirmationService, SelectItem} from "primeng/primeng";
import {BreadcrumbService} from "../breadcrumb.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {NGXLogger} from "ngx-logger";
import {MessageService} from "primeng/components/common/messageservice";
import {Order} from "../orders/order/order";
import {Contact} from "../contacts/contact/contact";
import {Address} from "../core/models/address";
import {Money} from "../core/models/money";
import {CcPayment} from "../orders/order/cc-payment";
import {OrderService} from "../orders/order.service";
import {EmailAddress, EmailType} from "../core/models/email-address";
import {PhoneNumber, PhoneNumberType} from "../core/models/phone-number";
import {environment} from "../../environments/environment";
import {EventService} from "../event/event.service";
import {Event as RegisteredEvent} from "../core/models/event";
import {TicketClass} from "../ticket-class/ticket-class";
import {Attendee} from "../core/models/attendee";
import {AuthService} from "../auth/auth.service";
import {RegistrationAnswer} from "../orders/order/registration-answer";

@Component({
    selector: 'app-event-registration',
    templateUrl: './event-registration.component.html',
    styleUrls: ['./event-registration.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class EventRegistrationComponent implements OnInit {
    order: Order;

    // domain
    states: SelectItem[];
    countries: SelectItem[];
    creditCardTypes: SelectItem[];
    creditCardExpirationDateMonths: SelectItem[];
    creditCardExpirationDateYears: SelectItem[];
    registrationForm: FormGroup;

    url = environment.apiUrl + 'po_file_upload';
    multiple = true;
    uploadedFiles: any[] = [];

    @Input() eventId: string = "5b5b6b3dad0b0f31c92825c1";
    @Input() orgId: string = "5b5b6b3bad0b0f31c92825bd";
    @Output() message = new EventEmitter();

    token = new Date().getUTCMilliseconds() + "-" + Math.random();
    fileUploaded = false;
    theEvent: RegisteredEvent;
    total: number = 0;
    ticketTypes: Map<string, TicketClass>;
    processing =false;
    constructor(
        private breadcrumbService: BreadcrumbService,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private orderService: OrderService,
        private logger: NGXLogger,
        private messageService: MessageService,
        private authService: AuthService,
        private confirmationService: ConfirmationService,
        private eventService: EventService,
        private router: Router) {
    }


    gotoComplete() {
        this.router.navigate(['registrationComplete']);
    }

    handleSave() {

        this.processing = true;
        this.messageService.clear();

        // if the hotel or cancellation policy was left 'No' do not allow registration
        if (!this.registrationForm.value.questions.cancelPolicyAgree) {
            this.messageService.add({
                severity: 'error',
                summary: 'Registration Failed - Cancellation Policy Consent Required',
                detail: 'Cancellation policy must be accepted to register'
            });
            return;
        }
        if (!this.registrationForm.value.questions.hotelPolicyAgree) {
            this.messageService.add({
                severity: 'error',
                summary: 'Registration Failed - Hotel Policy Consent Required',
                detail: 'Hotel policy must be accepted to register'
            });
            return;
        }

        // if only a guest pass was selected warn the user accordingly.
        // if(this.registrationForm.value.ticketType_2019guest && !this.registrationForm.value.ticketType_2019conference) {
        //   // add modal to warn user and allow them to correct if need be
        //   this.confirmGuestOnly();
        // }


        // validation complete - process order

        let o = new Order();
        o.owningOrganizationId = this.orgId;
        o.eventId = this.eventId;
        o.purchaseDate = new Date();

        o.attendees = new Array<Attendee>();
        let attendee = new Attendee();
        let contact = new Contact();

        contact.surName = this.registrationForm.value.surName;
        contact.givenName = this.registrationForm.value.givenName;
        contact.title = this.registrationForm.value.attendeeTitle;
        contact.organizationName = this.registrationForm.value.organization;
        contact.name = this.registrationForm.value.badgeName;
        if (this.registrationForm.value.attendeePhoneNo) {
            let phone = new PhoneNumber(PhoneNumberType.Work, this.registrationForm.value.attendeePhoneNo,
                true, false);
            contact.phoneNumbers = [phone];
        }
        if (this.registrationForm.value.emailCopyTo) {
            o.emailCopyTo = new EmailAddress(EmailType.Work, this.registrationForm.value.emailCopyTo,
                true, false);
        }
        if (this.registrationForm.value.attendeeEmail) {
            contact.emailAddresses = [new EmailAddress(EmailType.Work, this.registrationForm.value.attendeeEmail,
                true, false)];
        }

        // disclosures
        attendee.answers = new Array<RegistrationAnswer>();
        let cancel = this.registrationForm.value.questions.cancelPolicyAgree;
        let hotel = this.registrationForm.value.questions.hotelPolicyAgree;
        let photo = this.registrationForm.value.questions.photoPolicyAgree;
        attendee.answers.push(new RegistrationAnswer("cancelAgree", cancel));
        attendee.answers.push(new RegistrationAnswer("hotelPolicyAgree", hotel));
        attendee.answers.push(new RegistrationAnswer("photoPolicyAgree", photo));

        let paymentTotal = 0.00;

        let trainingType = this.registrationForm.value.trainingType;
        if (trainingType) {
            if (trainingType == "ticketType_2019Training_Beg") {
                attendee.ticketClasses.push(this.ticketTypes.get("begTraining2019"));
                paymentTotal += this.ticketTypes.get("begTraining2019").fees.map(r => r.amount).reduce((a, b) => a + b);
            } else if (trainingType == "ticketType_2019Training_Adv") {
                attendee.ticketClasses.push(this.ticketTypes.get("advTraining2019"));
                paymentTotal += this.ticketTypes.get("advTraining2019").fees.map(r => r.amount).reduce((a, b) => a + b);
            }
        }
        if (this.registrationForm.value.ticketType_2019conference) {
            attendee.ticketClasses.push(this.ticketTypes.get("attendee2019"));
            paymentTotal += this.ticketTypes.get("attendee2019").fees.map(r => r.amount).reduce((a, b) => a + b);
        }

        // add the user conference pass
        attendee.contact = contact;
        o.attendees.push(attendee);

        if (this.registrationForm.value.ticketType_20193dguest || this.registrationForm.value.ticketType_20195dguest) {
            if (!this.registrationForm.value.guestName) {
                this.messageService.add({
                    severity: 'error',
                    summary: 'A guest name is required',
                    detail: 'Enter a guest name or deselect guest pass.'
                });
                this.processing = false;
                return;
            }


            let guest = new Attendee();
            guest.contact = new Contact();
            guest.contact.name = this.registrationForm.value.guestName;
            if (this.registrationForm.value.ticketType_20193dguest) {
                guest.ticketClasses.push(this.ticketTypes.get("guest3d2019"));
                paymentTotal += this.ticketTypes.get("guest3d2019").fees.map(r => r.amount).reduce((a, b) => a + b);

            }
            if (this.registrationForm.value.ticketType_20195dguest) {
                guest.ticketClasses.push(this.ticketTypes.get("guest5d2019"));
                paymentTotal += this.ticketTypes.get("guest5d2019").fees.map(r => r.amount).reduce((a, b) => a + b);
            }
            o.attendees.push(guest);
        }


        let buyer = new Contact();
        buyer.givenName = this.registrationForm.value.givenName;
        buyer.surName = this.registrationForm.value.surName;
        buyer.name = contact.givenName + " "  + contact.surName;
        buyer.emailAddresses = [new EmailAddress(EmailType.Work, this.registrationForm.value.attendeeEmail,
            true, false)];
        buyer.phoneNumbers = [new PhoneNumber(PhoneNumberType.Work, this.registrationForm.value.attendeePhoneNo,
            true, false)];
        buyer.organizationName = this.registrationForm.value.organization;
        o.buyer = buyer;

        let payment;
        // if (this.registrationForm.value.billing.payBy == new CcPayment().type) {
        payment = new CcPayment();
        payment.fullName = this.registrationForm.value.billing.creditCardHolderName;
        payment.cardType = this.registrationForm.value.billing.creditCardType;
        payment.cardNumber = this.registrationForm.value.billing.creditCardNumber;
        payment.expMonth = this.registrationForm.value.billing.creditCardExpirationDateMo;
        payment.expYear = this.registrationForm.value.billing.creditCardExpirationDateYr;
        payment.last4 = payment.cardNumber.substr(payment.cardNumber.length - 4, payment.cardNumber.length);
        // } else {
        //   if( !this.fileUploaded) {
        //     this.messageService.add({severity: 'error',  summary: 'Registration Failed - Purchase Order required', detail: 'If paying by purchase order a copy of the purchase order (in PDF format) must be uploaded.  Select pay by PO, choose the PDF from your local drive and click the upload icon.'});
        //     return;
        //   }
        //   payment = new PoPayment();
        //   payment.token = this.token;
        // }
        payment.amount = new Money();
        payment.amount.name = "Tickets";
        payment.amount.currencyCode = "USD";
        payment.amount.amount = paymentTotal;

        payment.billingAddress = new Address();
        payment.billingAddress.streetAddress = this.registrationForm.value.billing.billingStreetAddress1;
        payment.billingAddress.streetAddress2 = this.registrationForm.value.billing.billingStreetAddress2;
        payment.billingAddress.locality = this.registrationForm.value.billing.billingLocality;
        payment.billingAddress.postalCode = this.registrationForm.value.billing.billingPostalCode;
        payment.billingAddress.region = this.registrationForm.value.billing.billingRegion;
        payment.billingAddress.country = this.registrationForm.value.billing.billingCountry;
        o.payment = payment;
        o.orderTotal = o.payment.amount;
        this.orderService.createOrder(o).subscribe(result => {
                this.messageService.add({severity: 'success', summary: this.theEvent.orderConfirmationMessage});
                this.resetForm();
                this.gotoComplete();
            },
            error => {
                this.processing = false;
                let messages = "";
                if (error.error) {
                    error.error.errors.forEach(a => messages += a.value);
                }
                this.messageService.add({severity: 'error', summary: 'Registration Failed', detail: messages});
            }
        );
    }

    resetForm() {
        this.messageService.clear();
        this.updateTotal();
        this.processing = false;
    }

    get ticketType_2019conference() {
        return this.registrationForm.get('ticketType_2019conference');
    }

    ngOnInit() {
        this.registrationForm
            = this.fb.group({
            'surName': new FormControl('', {validators: [Validators.required, Validators.minLength(2)]}),
            'givenName': new FormControl('', {validators: [Validators.required, Validators.minLength(2)]}),
            'attendeeTitle': new FormControl('', {validators: [Validators.required, Validators.minLength(2)]}),
            'organization': new FormControl('', {validators: [Validators.required, Validators.minLength(2)]}),
            'attendeeEmail': new FormControl('', {validators: [Validators.required, Validators.email]}),
            'guestName': new FormControl('', {}),
            'attendeePhoneNo': new FormControl('', {
                validators: [Validators.required,
                    Validators.minLength(10),
                    Validators.maxLength(20)]
            }),
            'badgeName': new FormControl('', {
                validators: [Validators.required,
                    Validators.minLength(2)]
            }),
            'emailCopyTo': new FormControl('', {validators: Validators.email,}),
            'trainingType': new FormControl('NONE', {}),
            'ticketType_20193dguest': new FormControl('', {}),
            'ticketType_20195dguest': new FormControl('', {}),
            'ticketType_2019conference': new FormControl('true', {}),
            'questions': this.fb.group({
                'cancelPolicyAgree': new FormControl('', {}),
                'hotelPolicyAgree': new FormControl('', {}),
                'photoPolicyAgree': new FormControl('', {}),
            }),
            // 'buyer': this.fb.group({
            //     'buyerSurName': new FormControl('', {validators: [Validators.required, Validators.minLength(2)]}),
            //     'buyerGivenName': new FormControl('', {validators: [Validators.required, Validators.minLength(2)]}),
            //     'buyerEmail': new FormControl('', {validators: [Validators.required, Validators.email]}),
            // }),
            'billing': this.fb.group({
                // 'payBy': new FormControl(new CcPayment().type, {}),
                'billingStreetAddress1': new FormControl('', {
                    validators: [Validators.required,
                        Validators.minLength(4)]
                }),
                'billingStreetAddress2': new FormControl('', {}),
                'billingLocality': new FormControl('', {
                    validators: [Validators.required,
                        Validators.minLength(2)]
                }),
                'billingRegion': new FormControl('', {validators: Validators.required}),
                'billingPostalCode': new FormControl('', {
                    validators: [Validators.required,
                        Validators.pattern('[0-9]+'), Validators.minLength(5)]
                }),
                'billingCountry': new FormControl('USA', {validators: Validators.required}),
                'creditCardType': new FormControl('',),
                'creditCardHolderName': new FormControl('', {
                    validators: [Validators.minLength(4), Validators.required]
                }),
                'creditCardNumber': new FormControl('', {
                    validators: [Validators.required, Validators.minLength(13), Validators.maxLength(16), Validators.pattern('[0-9]+')]
                }),
                'creditCardExpirationDateMo': new FormControl('', {validators: Validators.required}),
                'creditCardExpirationDateYr': new FormControl('', {validators: Validators.required})
            }),
        });

        this.countries = [];
        this.countries.push({label: 'United States of America', value: 'USA'});
        this.countries.push({label: 'Canada', value: 'Canada'});

        this.states = [];
        this.states.push({label: 'Select State', value: null});
        this.states.push({label: 'Alabama', value: 'AL'});
        this.states.push({label: 'Alaska', value: 'AK'});
        this.states.push({label: 'Arizona', value: 'AZ'});
        this.states.push({label: 'Arkansas', value: 'AR'});
        this.states.push({label: 'California', value: 'CA'});
        this.states.push({label: 'Colorado', value: 'CO'});
        this.states.push({label: 'Connecticut', value: 'CT'});
        this.states.push({label: 'Delaware', value: 'DE'});
        this.states.push({label: 'District of Columbia', value: 'DC'});
        this.states.push({label: 'Florida', value: 'FL'});
        this.states.push({label: 'Georgia', value: 'GA'});
        this.states.push({label: 'Hawaii', value: 'HI'});
        this.states.push({label: 'Idaho', value: 'ID'});
        this.states.push({label: 'Illinois', value: 'IL'});
        this.states.push({label: 'Indiana', value: 'IN'});
        this.states.push({label: 'Iowa', value: 'IA'});
        this.states.push({label: 'Kansas', value: 'KS'});
        this.states.push({label: 'Kentucky', value: 'KY'});
        this.states.push({label: 'Louisiana', value: 'LA'});
        this.states.push({label: 'Maine', value: 'ME'});
        this.states.push({label: 'Maryland', value: 'MD'});
        this.states.push({label: 'Massachusetts', value: 'MA'});
        this.states.push({label: 'Michigan', value: 'MI'});
        this.states.push({label: 'Minnesota', value: 'MN'});
        this.states.push({label: 'Mississippi', value: 'MS'});
        this.states.push({label: 'Missouri', value: 'MO'});
        this.states.push({label: 'Montana', value: 'MT'});
        this.states.push({label: 'Nebraska', value: 'NE'});
        this.states.push({label: 'Nevada', value: 'NV'});
        this.states.push({label: 'New Hampshire', value: 'NH'});
        this.states.push({label: 'New Jersey', value: 'NJ'});
        this.states.push({label: 'New Mexico', value: 'NM'});
        this.states.push({label: 'New York', value: 'NY'});
        this.states.push({label: 'North Carolina', value: 'NC'});
        this.states.push({label: 'North Dakota', value: 'ND'});
        this.states.push({label: 'Ohio', value: 'OH'});
        this.states.push({label: 'Oklahoma', value: 'OK'});
        this.states.push({label: 'Oregon', value: 'OR'});
        this.states.push({label: 'Pennsylvania', value: 'PA'});
        this.states.push({label: 'Rhode Island', value: 'RI'});
        this.states.push({label: 'South Carolina', value: 'SC'});
        this.states.push({label: 'South Dakota', value: 'SD'});
        this.states.push({label: 'Tennessee', value: 'TN'});
        this.states.push({label: 'Texas', value: 'TX'});
        this.states.push({label: 'Utah', value: 'UT'});
        this.states.push({label: 'Vermont', value: 'VT'});
        this.states.push({label: 'Virgina', value: 'VA'});
        this.states.push({label: 'Washington', value: 'WA'});
        this.states.push({label: 'West Virgina', value: 'WV'});
        this.states.push({label: 'Wisconsin', value: 'WI'});
        this.states.push({label: 'Wyoming', value: 'WY'});
        this.states.push({label: 'Alberta', value: 'AB'});
        this.states.push({label: 'British Columbia', value: 'BC'});
        this.states.push({label: 'Ontario', value: 'ON'});
        this.states.push({label: 'Manitoba', value: 'MB'});
        this.states.push({label: 'Quebec', value: 'QC'});
        this.states.push({label: 'Saskatchewan', value: 'SK'});


        this.creditCardExpirationDateMonths = [];
        this.creditCardExpirationDateMonths.push({label: 'Select', value: null});
        this.creditCardExpirationDateMonths.push({label: 'January', value: 1});
        this.creditCardExpirationDateMonths.push({label: 'February', value: 2});
        this.creditCardExpirationDateMonths.push({label: 'March', value: 3});
        this.creditCardExpirationDateMonths.push({label: 'April', value: 4});
        this.creditCardExpirationDateMonths.push({label: 'May', value: 5});
        this.creditCardExpirationDateMonths.push({label: 'June', value: 6});
        this.creditCardExpirationDateMonths.push({label: 'July', value: 7});
        this.creditCardExpirationDateMonths.push({label: 'August', value: 8});
        this.creditCardExpirationDateMonths.push({label: 'September', value: 9});
        this.creditCardExpirationDateMonths.push({label: 'October', value: 10});
        this.creditCardExpirationDateMonths.push({label: 'November', value: 11,});
        this.creditCardExpirationDateMonths.push({label: 'December', value: 12});

        this.creditCardExpirationDateYears = [];
        this.creditCardExpirationDateYears.push({label: '2018', value: '2018'});
        this.creditCardExpirationDateYears.push({label: '2019', value: '2019'});
        this.creditCardExpirationDateYears.push({label: '2020', value: '2020'});
        this.creditCardExpirationDateYears.push({label: '2021', value: '2021'});
        this.creditCardExpirationDateYears.push({label: '2022', value: '2022'});
        this.creditCardExpirationDateYears.push({label: '2023', value: '2023'});
        this.creditCardExpirationDateYears.push({label: '2024', value: '2024'});
        this.creditCardExpirationDateYears.push({label: '2025', value: '2025'});
        this.creditCardExpirationDateYears.push({label: '2026', value: '2026'});
        this.creditCardExpirationDateYears.push({label: '2027', value: '2027'});
        this.creditCardExpirationDateYears.push({label: '2028', value: '2028'});
        this.creditCardExpirationDateYears.push({label: '2029', value: '2029'});
        this.creditCardExpirationDateYears.push({label: '2030', value: '2030'});


        this.creditCardTypes = [];
        this.creditCardTypes.push({label: 'MasterCard', value: 'MasterCard'});
        this.creditCardTypes.push({label: 'Visa', value: 'Visa'});

        this.eventService.getEvent(this.eventId).subscribe(result => {
            this.theEvent = result;
            this.ticketTypes = new Map<string, TicketClass>();
            this.theEvent.ticketClasses.map(t => this.ticketTypes.set(t.sku, t)),
                error => {
                    let messages = "";
                    this.messageService.add({severity: 'error', summary: 'Event details not found', detail: messages});
                }
                this.updateTotal();
        });
    }
    showProcessing() {

    }
    updateTotal() {
        this.total = 0;
        let trainingType = this.registrationForm.value.trainingType;
        if (trainingType) {
            if (trainingType == "ticketType_2019Training_Beg") {
                this.total += this.ticketTypes.get("begTraining2019").fees.map(r => r.amount).reduce((a, b) => a + b);
            } else if (trainingType == "ticketType_2019Training_Adv") {
                this.total += this.ticketTypes.get("advTraining2019").fees.map(r => r.amount).reduce((a, b) => a + b);
            }
        }

        if (this.registrationForm.value.ticketType_20193dguest) {
            this.total += this.ticketTypes.get("guest3d2019").fees.map(r => r.amount).reduce((a, b) => a + b);

        }
        if (this.registrationForm.value.ticketType_20195dguest) {
            this.total += this.ticketTypes.get("guest5d2019").fees.map(r => r.amount).reduce((a, b) => a + b);

        }
        if (this.registrationForm.value.ticketType_2019conference) {
            this.total += this.ticketTypes.get("attendee2019").fees.map(r => r.amount).reduce((a, b) => a + b);
        }
    }


    // onBeforeUpload(event: any) {
    //     event.formData.append('organizationId', this.orgId);
    //     event.formData.append('token', this.token);
    // }


    // onUpload(event) {
    //   for (const file of event.files) {
    //     this.uploadedFiles.push(file);
    //   }
    //   this.messageService.add({severity: 'info',  summary: 'File Uploaded', detail: ''});
    //
    //   // this.msgs = [];
    //   // this.msgs.push({severity: 'info', summary: 'File Uploaded', detail: ''});
    //   // this.message.emit('File uploaded successfully. It will now be processed.');
    //   this.fileUploaded = true;
    // }
    //
    // onError() {
    //     this.messageService.add({severity: 'error',  summary: 'Error uploading file.', detail: ''});
    //   //this.message.emit('Error uploading file.');
    //   this.fileUploaded = false;
    // }
    // < file upload
}