/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import {EventEmitter, Injectable, Output} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from "@angular/common/http";
import {User} from "../user-profile/user";
import {Token} from "./token";
import {NGXLogger} from "ngx-logger";
import {Observable} from "rxjs";
import {mergeMap, tap} from "rxjs/operators";
import {UserService} from "../user-profile/user.service";


const USER_KEY_NAME = "user";
const LOGOUT_URL = environment.apiUrl + 'api/logout';

@Injectable()
export class AuthService {

    private readonly GET_TOKEN_URL = environment.apiUrl + 'oauth/token';

    private isSessionAuthenticated = false;

    @Output() authChanged: EventEmitter<User> = new EventEmitter<User>();
    @Output() authVerified: EventEmitter<User> = new EventEmitter<User>();

    constructor(private router: Router, private http: HttpClient, private userService: UserService,
                private logger: NGXLogger) {
    }

    public getUser(): User { // this should be getAuthorizedUser
        return this.get(USER_KEY_NAME);
    }

    public isAuthenticated(): boolean {
        return this.isSessionAuthenticated;
    }

    private get(key: string) {
        let item = sessionStorage.getItem(key);
        if (item != null)
            return JSON.parse(item);
    }

    private store(key: string, val) {
        sessionStorage.setItem(key, JSON.stringify(val));
    }

    private delete(key: string) {
        sessionStorage.removeItem(key);
    }

    public validateSession(){
        const userInSessionStorage = this.getUser();
        if (!userInSessionStorage) {
            this.isSessionAuthenticated = false;
            return null;
            // return of(null);
        }

        const token = userInSessionStorage.details.tokenValue;
        return this.userService.getLoggedInUser(token).pipe(tap(
            user => {
                this.logger.debug('validate session', user)
                if (user) this.isSessionAuthenticated = true;
                return user;
            }
                // return of(user);
            // },
            // error => {
            //     this.logger.error('Encountered and error validating token', error)
            //     this.isSessionAuthenticated = false;
            //     this.delete(USER_KEY_NAME);
            //     return null;
            //     // return of(null);
            // }
        ))
    }

    getToken(): string {
        let user = this.getUser();
        if (user) {
            return user.details.tokenValue;
        } else {
            return null;
        }
    }

    login(username: string, password: string) {
        // clear cache
        this.delete(USER_KEY_NAME);
        // do auth
        let params = new HttpParams()
            .append('username', username)
            .append('password', password)
            .append('grant_type', 'password')
            .append('scope', 'read');
        let headers = new HttpHeaders({
            'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'Authorization': 'Basic ' + btoa("testclient:password"),
        });
        // QUESTION: do we want to separate the logic to get the user out of this function?
        let tf = this.http.post<Token>(this.GET_TOKEN_URL, params.toString(), {headers});
        return tf.pipe(
            mergeMap(tok =>
                this.userService.getLoggedInUser(tok.access_token)
                    .pipe(
                        tap(user => {
                            this.isSessionAuthenticated = true;
                            this.delete(USER_KEY_NAME);
                            this.store(USER_KEY_NAME, user);
                            this.authChanged.emit(user);
                        })
                    )
            )
        );
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof Error) {
            this.logger.error("its an error " + error.error.message);
            let errMessage = error.error.message;
            return Observable.throw(errMessage);
        }
        return Observable.throw(error || 'Server error');
    }

    logout(): boolean {
        this.delete(USER_KEY_NAME);
        this.isSessionAuthenticated = false;
        this.router.navigateByUrl('/login');
        return true;
    }
}

export const AUTH_PROVIDERS: Array<any> = [
    {provide: AuthService, useClass: AuthService}
];
