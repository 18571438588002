import { Component, Input, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MenuItem } from 'primeng/primeng';
import { AppShellComponent } from './app-shell/app-shell.component';
import { User } from "./user-profile/user";
import { NGXLogger } from "ngx-logger";
import { StoreService } from './core/services/store.service';
import {Subscription} from "rxjs";
import {AuthService} from "./auth/auth.service";

@Component({
  selector: 'app-menu',
  template: `
    <ul app-submenu [item]="model" root="true" class="layout-menu" [reset]="reset" visible="true"></ul>
  `
})
export class AppMenuComponent implements OnInit {

  @Input() reset: boolean;

  model: any[];
  private subscription: Subscription;
  organizationId: string;

  constructor(
    public app: AppShellComponent,
    private storeService: StoreService,
    private authService: AuthService,
    private logger: NGXLogger
  ) {}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  setModel(orgId) {
    this.model = [
      {
        label: 'Dashboard',
        icon: 'fa fa-fw fa-dashboard',
        routerLink: [ '/organizations/' + orgId ]
      },
      // {
      //   label: 'Customers',
      //   icon: 'fa fa-fw fa-address-book',
      //   routerLink: ['/organizations']
      // },
      {
        label: 'Products',
        icon: 'fa fa-fw fa-cube',
        routerLink: [ '/organizations/' + orgId + '/products' ]
      },
      {
        label: 'Orders',
        icon: 'fa fa-shopping-bag',
        routerLink: [ '/organizations/' + orgId + '/orders' ]
      },
      {
        label: 'Reports',
        icon: 'fa fa-line-chart',
        items: [
          {
            label: 'Year over Year',
            icon: 'fa fa-line-chart',
            routerLink: [ '/organizations/' + orgId + '/reports/yearOverYear' ]
          },
          {
            label: 'Sales Trends',
            icon: 'fa fa-line-chart',
            routerLink: [ '/organizations/' + orgId + '/reports/salesAndProfit' ]
          }
        ],
      },
    ];
  }

  ngOnInit() {
    const item = sessionStorage.getItem('user');
    if (item != null) {
      const userObj = JSON.parse(item);
      this.setModel(userObj.principal.organizationId);
    }

    this.storeService.orgId
      .subscribe(
        orgId => {
          this.setModel(orgId);
        }
      )

    this.subscription = this.authService.authChanged
      .subscribe((user: User) => {
          if (user) {
            this.logger.debug("app.menu.component.ngOnInit " + user.principal.organizationId);
            this.organizationId = user.principal.organizationId;


            this.model = [
              {
                label: 'Dashboard', icon: 'fa fa-fw fa-dashboard',
                routerLink: ['/organizations/' + this.authService.getUser().principal.organizationId]
              },
              {label: 'Customers', icon: 'fa fa-fw fa-address-book', routerLink: ['/organizations']},
              {
                label: 'Products',
                icon: 'fa fa-fw fa-cube',
                routerLink: ['/organizations/' + this.authService.getUser().principal.organizationId + "/products"]
              },
              {
                label: 'Orders',
                icon: 'fa fa-shopping-bag',
                routerLink: ['/organizations/' + this.authService.getUser().principal.organizationId + "/orders"]
              },
            ];
          }
        },
        (err: any) => this.logger.error(err));
  }
}

@Component({
  /* tslint:disable:component-selector */
  selector: '[app-submenu]',
  /* tslint:enable:component-selector */
  template: `
    <ng-template ngFor let-child let-i="index" [ngForOf]="(root ? item : item.items)">
      <li [ngClass]="{'active-menuitem': isActive(i)}" [class]="child.badgeStyleClass"
          *ngIf="child.visible === false ? false : true" >
        <a [href]="child.url||'#'" (click)="itemClick($event,child,i)" (mouseenter)="onMouseEnter(i)"
           *ngIf="!child.routerLink" [ngClass]="child.styleClass"
           [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
          <i [ngClass]="child.icon"></i>
          <span>{{child.label}}</span>
          <i class="fa fa-fw fa-angle-down layout-menuitem-toggler" *ngIf="child.items"></i>
          <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
        </a>

        <a (click)="itemClick($event,child,i)" (mouseenter)="onMouseEnter(i)" *ngIf="child.routerLink"
           [routerLink]="child.routerLink" routerLinkActive="active-menuitem-routerlink" [routerLinkActiveOptions]="{exact: true}" [attr.tabindex]="!visible ? '-1' : null"
           [attr.target]="child.target">
          <i [ngClass]="child.icon"></i>
          <span>{{child.label}}</span>
          <i class="fa fa-fw fa-angle-down" *ngIf="child.items"></i>
          <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
        </a>
        <div class="layout-menu-tooltip">
          <div class="layout-menu-tooltip-arrow"></div>
          <div class="layout-menu-tooltip-text">{{child.label}}</div>
        </div>
        <ul app-submenu [item]="child" *ngIf="child.items" [visible]="isActive(i)" [reset]="reset"
            [@children]="(app.isSlim()||app.isHorizontal())&&root ? isActive(i) ?
                    'visible' : 'hidden' : isActive(i) ? 'visibleAnimated' : 'hiddenAnimated'"></ul>
      </li>
    </ng-template>
  `,
  animations: [
    trigger('children', [
      state('hiddenAnimated', style({
        height: '0px'
      })),
      state('visibleAnimated', style({
        height: '*'
      })),
      state('visible', style({
        height: '*'
      })),
      state('hidden', style({
        height: '0px'
      })),
      transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class AppSubMenuComponent {

  @Input() item: MenuItem;

  @Input() root: boolean;

  @Input() visible: boolean;

  _reset: boolean;

  activeIndex: number;

  constructor(public app: AppShellComponent) {
  }

  itemClick(event: Event, item: MenuItem, index: number) {
    if (this.root) {
      this.app.menuHoverActive = !this.app.menuHoverActive;
    }
    // avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
      return true;
    }

    // activate current item and deactivate active sibling if any
    this.activeIndex = (this.activeIndex === index) ? null : index;

    // execute command
    if (item.command) {
      item.command({originalEvent: event, item: item});
    }

    // prevent hash change
    if (item.items || (!item.url && !item.routerLink)) {
      event.preventDefault();
    }

    // hide menu
    if (!item.items) {
      if (this.app.isHorizontal() || this.app.isSlim()) {
        this.app.resetMenu = true;
      } else {
        this.app.resetMenu = false;
      }

      this.app.overlayMenuActive = false;
      this.app.staticMenuMobileActive = false;
      this.app.menuHoverActive = !this.app.menuHoverActive;
    }
  }

  onMouseEnter(index: number) {
    if (this.root && this.app.menuHoverActive && (this.app.isHorizontal() || this.app.isSlim())) {
      this.activeIndex = index;
    }
  }

  isActive(index: number): boolean {
    return this.activeIndex === index;
  }

  @Input() get reset(): boolean {
    return this._reset;
  }

  set reset(val: boolean) {
    this._reset = val;

    if (this._reset && (this.app.isHorizontal() || this.app.isSlim())) {
      this.activeIndex = null;
    }
  }
}