import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {Observable} from "rxjs/index";
import {HttpClient} from "@angular/common/http";
import {BaseService} from "../core/services/base-service";
import {NGXLogger} from "ngx-logger";
import {Event,EventResponse} from "../core/models/event";
const EVENT_BASE_URL = environment.apiUrl + 'api/events/';

@Injectable({
  providedIn: 'root'
})
export class EventService extends BaseService {

  constructor(private http: HttpClient, private logger: NGXLogger) {
    super();
  }

  public getEvents(options): Observable<EventResponse> {
    let url = EVENT_BASE_URL
      + 'search/findByQuery?'
      + `organizationId=${options.orgId}&`
      + `page=${options.page || 0}`;

    return this.http.get<EventResponse>(url);
  }

  public getEvent(id: string): Observable<Event> {
    let url = EVENT_BASE_URL + id;
      return this.http.get<Event>(url);
  }

  public createEvent(Event: Event): Observable<Event> {
    return this.http
      .post<Event>(EVENT_BASE_URL, Event)
  }

  public updateEvent(event: Event): Observable<Event> {
    return this.http
      .put<Event>(EVENT_BASE_URL + event.id, Event)
    // .catch((error: any) => this.handleError(error));
  }
}