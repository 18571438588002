import {Component, Injectable, OnDestroy, OnInit} from '@angular/core';
import {BreadcrumbService} from "../breadcrumb.service";
import {MessageService} from "primeng/components/common/messageservice";
import {ActivatedRoute} from "@angular/router";
import {Order} from "./order/order";
import {OrderService} from "./order.service";
import {NGXLogger} from "ngx-logger";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-orders',
    templateUrl: './orders.component.html',
})
@Injectable()
export class OrdersComponent implements OnInit, OnDestroy {
    orgId: string;
    orders: Array<Order> = [];
    selected: Order;
    add$: Subscription;
    edit$: Subscription;
    loading: boolean = true;
    cols: any[];
    selectedOrders: Order[];
    first: number = 0;
    totalRecords: number = 0;
    rowsSize: number = 20;
    periodStart: Date = new Date('2018-01-01');
    periodEnd: Date = new Date;
    rowsPerPageOptions: Array<number> = [ 20, 35, 50, 100 ];

    constructor(
        private orderService: OrderService,
        private messageService: MessageService,
        private route: ActivatedRoute,
        private logger: NGXLogger,
        private breadcrumbService: BreadcrumbService
    ) {}

    onRowSelect(event) {
        if (this.selected) {
            // this.OrderForDialog = Object.assign({}, this.selected);
        }
    }

    reset() {
        this.logger.info("OrdersComponent.reset()");
        this.first = 0;
    }

    ngOnInit() {
        this.orgId = this.route.snapshot.paramMap.get('orgId');
        this.cols = [
            { field: 'amazonOrderId', header: 'Order ID' },
            { field: 'orderTotal', header: 'Order Total' },
            { field: 'purchaseDate', header: 'Purchase Date' },
            { field: 'orderStatus', header: 'Status' },
            { field: 'salesChannel', header: 'Channel' },
            { field: 'prime', header: 'Prime' },
        ];

        this.getOrders({
            orgId: this.orgId,
            periodStart: this.formatDate(this.periodStart),
            periodEnd: this.formatDate(this.periodEnd),
        });

        this.breadcrumbService.setItems([
            {label: 'Home', routerLink: ['/organizations', this.orgId], icon: 'fa fa-home'},
            {label: 'Orders', routerLink: ['/organizations', this.orgId, "orders"]},
        ]);
    }

    getOrders(options) {
        this.loading = true;
        this.orderService.getOrders(options)
            .subscribe(
                orders => {
                    this.orders = orders._embedded.orders;
                    this.totalRecords = orders.page.totalElements;
                    this.loading = false;
                },
                error => {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Error loading orders.',
                        detail: error
                    });
                    this.loading = false;
                }
            );
    }

    filterOrders() {
        this.getOrders({
            orgId: this.orgId,
            periodStart: this.formatDate(this.periodStart),
            periodEnd: this.formatDate(this.periodEnd),
        });
    }

    onLazyLoad(event) {
        const { first:offset, rows } = event;
        const page = (offset)  / event.rows;
        console.log(event);

        this.getOrders({
            orgId: this.orgId,
            periodStart: this.formatDate(this.periodStart),
            periodEnd: this.formatDate(this.periodEnd),
            size: rows,
            page: page
        });
    }

    private formatDate(date) {
        const year = date.getUTCFullYear();
        const month = date.getUTCMonth() + 1;
        const day = date.getUTCDate();

        let stringDate = year;
        stringDate += '-';
        if (month < 10) stringDate += '0';
        stringDate += month;
        stringDate += '-';
        if (day < 10) stringDate += '0';
        stringDate += day;

        return stringDate;
    }

    private showError(errMsg: string) {
        this.messageService.add({
            severity: 'error',
            summary: 'An error occurred creating Order',
            detail: errMsg
        });
    }

    private showSuccess(successMsg: string) {
        this.messageService.add({severity: 'success', detail: successMsg});
    }

    ngOnDestroy() {
        if (this.add$) {
            this.add$.unsubscribe();
        }
        if (this.edit$) {
            this.edit$.unsubscribe();
        }
    }
}
