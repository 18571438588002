import {Contact} from "../../contacts/contact/contact";
import {Money} from "../../core/models/money";
import {Payment} from "./payment";
import {BaseModel} from "../../core/models/base-model";
import {PaymentAck} from "./payment-ack";
import {EmailAddress} from "../../core/models/email-address";
import {Attendee} from "../../core/models/attendee";

export class Order extends BaseModel {
    externalOrderId: string;
    eventId: string;
    buyer: Contact;
    attendees: Array<Attendee>;
    purchaseDate: Date;
    orderTotal: Money;
    payment: Payment;
    paymentAck: PaymentAck;
    emailCopyTo: EmailAddress;
}

export interface Embedded {
    orders: Array<Order>;
}

export interface OrderResponse {
    _embedded: Embedded;
    page: Page;

}
export interface Page {
    size: number;
    totalElements: number;
    totalPages: number;
    number: number;
}