/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { EventEmitter, Injectable, Output } from "@angular/core";

import { NGXLogger} from "ngx-logger";

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  @Output()
  orgId: EventEmitter<string> = new EventEmitter<string>();
  organizationId: string = '';

  constructor(
    // private router: Router,
    private logger: NGXLogger
  ) {}

  changeOrg(orgId: string) {
    this.organizationId = orgId;
    this.orgId.emit(orgId);
  }

  getOrgId() {
    return this.organizationId;
  }
}
