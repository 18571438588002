import { Injectable } from '@angular/core';
import {BaseService} from "../core/services/base-service";
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs/internal/Observable";
import {Organization, OrganizationResponse} from "./organization";

@Injectable({
  providedIn: 'root'
})

@Injectable()
export class OrganizationService extends BaseService {
  private readonly URL = environment.apiUrl + 'api/organizations/';

  constructor(private http: HttpClient) {
    super();
  }

  public getOrganization(id: String) : Observable<Organization> {
    return this.http.get<Organization>(this.URL+id)
    // .catch((error: any) => this.handleError(error));
  }

  public getOrganizations(): Observable<OrganizationResponse> {
    return this.http.get<OrganizationResponse>(this.URL)
    // .catch((error: any) => this.handleError(error));
  }

  public createOrganization(organization: Organization): Observable<Organization> {
    return this.http
      .post<Organization>(this.URL, organization)
    // .catch((error: any) => this.handleError(error));
  }

  public updateOrganization(organization: Organization): Observable<Organization> {
    return this.http
      .put<Organization>(this.URL+organization.id, organization)
    // .catch((error: any) => this.handleError(error));
  }
}