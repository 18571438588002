import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {NGXLogger} from "ngx-logger";
import {BaseService} from "../core/services/base-service";
import {environment} from "../../environments/environment";
import {Order, OrderResponse} from "./order/order";

const ORDER_BASE_URL = environment.apiUrl + 'api/register/';

@Injectable({
  providedIn: 'root'
})
export class OrderService extends BaseService {

  constructor(private http: HttpClient, private logger: NGXLogger) {
    super();
  }

  public getOrders(options): Observable<OrderResponse> {
    let url = ORDER_BASE_URL
      + 'search/findByQuery?'
      + `organizationId=${options.orgId}&`
      + `page=${options.page || 0}`;


    return this.http.get<OrderResponse>(url);
  }

  // public getOrderByAmazonOrderId(organizationId: string, amazonOrderId: string): Observable<Order> {
  //   let url = ORDER_BASE_URL + `search/findByOrganizationIdAndAmazonOrderId?organizationId=${organizationId}&amazonOrderId=${amazonOrderId}`;
  //   return this.http.get<Order>(url);
  // }

  public getOrder(id: string): Observable<Order> {
    let url = ORDER_BASE_URL + id;
    return this.http.get<Order>(url);
  }

  public createOrder(order: Order): Observable<Order> {
    return this.http
      .post<Order>(ORDER_BASE_URL, order)
  }

  public updateOrder(order: Order): Observable<Order> {
    return this.http
      .put<Order>(ORDER_BASE_URL + order.id, order)
    // .catch((error: any) => this.handleError(error));
  }
}
