import {TicketClass} from "../../ticket-class/ticket-class";
import {RegistrationAnswer} from "../../orders/order/registration-answer";
import {Contact} from "../../contacts/contact/contact";

export class Attendee {
    contact: Contact;
    ticketClasses: Array<TicketClass> = new Array();
    answers: Array<RegistrationAnswer> = new Array();
}

export interface Embedded {
    contacts: Array<Attendee>;
}

export interface AttendeeResponse {
    _embedded: Embedded;
}

