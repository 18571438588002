import {Component, Input, OnInit} from '@angular/core';
import {Order} from "../../orders/order/order";

@Component({
  selector: 'app-event-registration-complete',
  templateUrl: './event-registration-complete.component.html',
  styleUrls: ['./event-registration-complete.component.css']
})
export class EventRegistrationCompleteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
