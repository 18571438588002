import {Model} from "./model";

export class BaseModel implements Model {
  id?: string;
  owningOrganizationId?: string;
  createdBy?: string;
  createdOn?: Date;
  lastEditBy?: string;
  lastEditOn?: Date;

}
