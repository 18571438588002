import {BaseModel} from "./base-model";
import {TicketClass} from "../../ticket-class/ticket-class";
import {Question} from "./question";
export class Event extends BaseModel {
  name: string;
  description: string;
  ticketClasses: TicketClass[];
  externalEventId: string;
  orderConfirmationMessage: string;
  url: string;
  questions: Question[];
}

export interface Embedded {
  orders: Array<Event>;
}

export interface EventResponse {
  _embedded: Embedded;
  // page: Page;

}

