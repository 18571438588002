/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import {Component} from '@angular/core';
import {AuthService} from '../auth/auth.service'
import {Message} from "primeng/primeng";
import {MessageService} from "primeng/components/common/messageservice";
import {ActivatedRoute, Router} from "@angular/router";
import {NGXLogger} from 'ngx-logger';

@Component({
    selector: 'app-login',
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.scss']

})
export class LoginComponent {
    msgs: Message[] = [];
    public loginData = {username: "", password: ""};
    private loading = false;

    constructor(private authService: AuthService, private messageService: MessageService, private route: ActivatedRoute,
                private router: Router, private logger: NGXLogger) {
    }

    login(event) {
        this.loading = true;
        this.authService
            .login(this.loginData.username, this.loginData.password)
            .subscribe(
                data => {
                    this.logger.debug(`NGXLogger.debug - LoginComponent.login user[${data}]`);
                    this.router.navigateByUrl(`/organizations/${this.authService.getUser().principal.organizationId}`);
                    // this.loading = false;
                },
                error => {
                    // login failed so display error
                    console.log('AUTH ERROR', error);
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Login Failed',
                        detail: error.message
                    });
                    this.loading = false;
                }
            );
    }
}
