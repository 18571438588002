import {Address} from "../../core/models/address";
import {PhoneNumber} from "../../core/models/phone-number";
import {EmailAddress} from "../../core/models/email-address";
import {BaseModel} from "../../core/models/base-model";

export class Contact extends BaseModel {
  name: string;
  givenName: string;
  middleName?: string;
  surName: string;
  dateOfBirth?: Date;
  addresses: Array<Address>;
  title?:string;
  salutation?:string;
  phoneNumbers?:Array<PhoneNumber>;
  emailAddresses?:Array<EmailAddress>;
  facebookId?:string;
  instagramId?:string;
  twitterId?:string;
  avatar?:string;
  organizationId: string;
  organizationName: string;
}

export interface Embedded {
  contacts: Array<Contact>;
}

export interface ContactResponse {
  _embedded: Embedded;
}
