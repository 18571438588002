import {Observable, of} from 'rxjs';
import {HttpErrorResponse} from "@angular/common/http";
import {NGXLogger} from "ngx-logger";

export abstract class BaseService {
  public constructor() {
  }


  protected handleError(error: HttpErrorResponse) {
    throw error;
    // return Observable.throw(error.statusText);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  public handleError2<T>(operation = 'operation', result?: T) {

    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  public log(logger: NGXLogger, message: string) {
    console.error(message);
    // this.messageService.add({severity: 'error', summary: message});
  }
}
