
import { Component, OnInit } from '@angular/core';import { CarService } from '../demo/service/carservice';
import { EventService } from '../demo/service/eventservice';
import { Car } from '../demo/domain/car';
import { SelectItem } from 'primeng/primeng';
import { MenuItem } from 'primeng/primeng';
import { BreadcrumbService } from '../breadcrumb.service';
@Component({
  selector: 'app-patients-dashboard',
  templateUrl: './patients-dashboard.component.html',
  styleUrls: ['./patients-dashboard.component.css']
})
export class PatientsDashboardComponent implements OnInit {

  cities: SelectItem[];

  cars: Car[];

  cols: any[];

  chartData: any;

  events: any[];

  selectedCity: any;

  selectedCar: Car;

  items: MenuItem[];

  pieData: any;


  constructor(private carService: CarService, private eventService: EventService, private breadcrumbService: BreadcrumbService) {
    this.breadcrumbService.setItems([
      { label: ' ', routerLink: [''] }
    ]);
  }

  ngOnInit() {
    this.carService.getCarsMedium().then(cars => this.cars = cars);

    this.cols = [
      { field: 'vin', header: 'Vin' },
      { field: 'year', header: 'Year' },
      { field: 'brand', header: 'Brand' },
      { field: 'color', header: 'Color' }
    ];

    this.eventService.getEvents().then(events => { this.events = events; });

    this.cities = [];
    this.cities.push({ label: 'Select City', value: null });
    this.cities.push({ label: 'New York', value: { id: 1, name: 'New York', code: 'NY' } });
    this.cities.push({ label: 'Rome', value: { id: 2, name: 'Rome', code: 'RM' } });
    this.cities.push({ label: 'London', value: { id: 3, name: 'London', code: 'LDN' } });
    this.cities.push({ label: 'Istanbul', value: { id: 4, name: 'Istanbul', code: 'IST' } });
    this.cities.push({ label: 'Paris', value: { id: 5, name: 'Paris', code: 'PRS' } });

    this.chartData = {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
      datasets: [
        {
          label: 'Cohort 1',
          data: [, 2, 1, 3, 6, 8],
          fill: false,
          borderColor: '#4caf50'
        },
        {
          label: 'Cohort 2',
          data: [, 6, 3, 2, 7, 9],
          fill: false,
          borderColor: '#39a3f4'
        }
      ]
    };

    this.items = [
      { label: 'Save', icon: 'fa fa-check' },
      { label: 'Update', icon: 'fa fa-refresh' },
      { label: 'Delete', icon: 'fa fa-trash' }
    ];

    this.pieData = {
      labels: ['Happy & Healthy','BonTwoDos','Feeling Loved'],
      datasets: [
        {
          data: [50, 30, 20],
          backgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56"
          ],
          hoverBackgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56"
          ]
        }]
    };
  }
}
