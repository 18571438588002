// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
// apiUrl: 'http://ec2-18-215-29-38.compute-1.amazonaws.com/',
export const environment = {
  production: false,
    apiUrl: 'https://awapi.assetworks.hosting/',
    // apiUrl: 'http://localhost:5000/',
    isDebugMode: true
};