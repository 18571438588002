/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {HttpErrorResponse} from "@angular/common/http";
import {Router} from "@angular/router";
import {AuthService} from "./auth/auth.service";
import {Cookie} from "ng2-cookies";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {
  }

  handleError(error) {
    console.log(error)
    // error.subscribe(e => {
    //     if (e instanceof HttpErrorResponse) {
    //       const date = new Date().toISOString();
    //       console.error(date, 'There was an HTTP error.', error.message, 'Status code:', (<HttpErrorResponse>error).status);
    //     }
    //   }
    // );

    if (error instanceof HttpErrorResponse) {
      const router = this.injector.get(Router);
      if (error.status === 401 || error.status === 403) {
        // todo: is there any state that should be cleaned?
        // this.authService.logout();
        router.navigate(['/login']);
      }
    }
  }
}
