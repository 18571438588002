import {Component} from '@angular/core';

@Component({
    selector: 'app-footer',
    styleUrls: ['./app.footer.component.css'],
    template: `
      <div class="footer layout-footer">
        <div class="clearfix">
          <span class="footer-text-left">&copy; 2018 AssetWorks LLC</span>
          <span class="footer-text-right"></span>
        </div>
      </div>
    `
})
export class AppFooterComponent {

}
