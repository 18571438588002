import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from "rxjs";
import {BaseService} from "../core/services/base-service";
import {environment} from "../../environments/environment";
import {User} from "./user";

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {
  private readonly URL = environment.apiUrl + 'user';

  constructor(private http: HttpClient) {
    super();
  }

  public getLoggedInUser(accessToken: string): Observable<User> {
    let headers = new HttpHeaders({
      'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      'Authorization': `Bearer ${accessToken}`,
    });
    return this.http.get<User>(this.URL,{headers});
  }
}
